import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { getCompanyGlobalScore, getCompanyGlobalScoreLabel, getCompanyTotalReviews, getCompanyDrivingAddressLink } from '../utilities/companyHelpers'
import algoliasearch from 'algoliasearch/lite';
/* https://medium.com/@chrisfitkin/how-to-get-query-string-parameter-values-in-gatsby-f714161104f */
import queryString from 'query-string'

import SidebarFilter from '../components/sidebar-filter'
import CompanyRowView from '../components/company-rowview'
import Pagination from '../components/pagination'

const SearchResults = (props) => {

  // Client-side Runtime Data Fetching
  const [starsCount, setStarsCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(queryString.parse(props.location.search).q)
  const [filterByCategory, setFilterByCategory] = useState([]);
  const [filterByRating, setFilterByRating] = useState([]);
  const [myCompanies, setMyCompanies] = useState([]);  
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [ratings, setRatings] = useState(
    [ 
      { id : "1", name : "Muy Confiable (4-5)", lowValue : 4, highValue : 5 },
      { id : "2", name : "Alto  (3-4)", lowValue : 3, highValue : 4 },
      { id : "3", name : "Medio (2-3)", lowValue : 2, highValue : 3 },
      { id : "4", name : "Bajo (1-2)", lowValue : 1, highValue : 2 },
      { id : "5", name : "Ninguno (0-1)", lowValue : 0, highValue : 1 }
    ]);
  const client = algoliasearch("JP5RXELMD1", "7e964a6d1abe56f217e7d929ca165494");
  const index = client.initIndex("companies");
  const categoryIndex = props.data.allContentfulCategory.edges;    

  // With the "useEffect" function of React, we can trigger the "initSearch" function
  // when the state(s) (defined in the second parameter) changes. In this case, every time
  // filter by category is updated, or filter by rating is updated, the initSearch
  // will be trigerred. 
  //
  // Info: https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect,
  useEffect(initSearch, [ searchTerm, filterByCategory, filterByRating, pageSize ]);

  function getCategoryNameFromId(categoryId) {
    var categoryIndexMatch = props.data.allContentfulCategory.edges.filter(categoryIndexItem => 
      {
        return categoryIndexItem.node.contentful_id === categoryId
      });

    var categoryName = "Sin Categoria";

    if(categoryIndexMatch && categoryIndexMatch.length > 0)
      categoryName = categoryIndexMatch[0].node.name

    return categoryName;
  }

  /* 
    Transform the categories data that exist inside the facets json object
    into an object that is suitable for the search sidebar.
  */
  function mapToCategoriesFilter(facets)
  {
      //console.log("Reviewing facets...");
      //console.log(JSON.stringify(facets["entry.categoriesCollection.items.sys.id"]));

      var categories = [];

      if(!facets)
        return categories;

      var propertyIds = facets["entry.categoriesCollection.items.sys.id"];

      for (var key in propertyIds) {
        if (propertyIds.hasOwnProperty(key)) 
        {
            categories.push(
            {
              id : key,
              initialCount : propertyIds[key],
              name : getCategoryNameFromId(key)
            })            
        }
      }

      //console.log("Extracted categories...");
      //console.log(JSON.stringify(categories));

      return categories;
  }

  function onLoadMoreClick(event) {
    event.preventDefault();

    setPageSize(pageSize + 25);
  }

  function onSearchTermClick(event) {
    event.preventDefault();

    //setSearchTerm()
  }

  function onSearchTermChange(event) {
    event.preventDefault();

    setSearchTerm(event.target.value);
  }

  function onFilterCategoryChange(event) {

    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    // create a copy of the variable using "..." syntax, do not modify
    // the state directly, otherwise an error will happen.
    let newFilterByCategory = [... filterByCategory] || [];

    if(checked && newFilterByCategory.filter(x => x == name).length == 0)
      newFilterByCategory.push(name)
    else
      newFilterByCategory = newFilterByCategory.filter(x => x != name);

    setFilterByCategory(newFilterByCategory);
  }

  function onFilterRatingChange(event) {
   
    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    // create a copy of the variable using "..." syntax, do not modify
    // the state directly, otherwise an error will happen.
    let newFilterByRating = [... filterByRating] || [];

    if(checked && newFilterByRating.filter(x => x == name).length == 0)
      newFilterByRating.push(name)
    else
      newFilterByRating = newFilterByRating.filter(x => x != name);

    setFilterByRating(newFilterByRating);
  }

  function initSearch(){

    // Algolia Doc: https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/
    var defaultFacetFilters = [];
    var defaultNumbericFilters = []; // used only for numeric values, like number ranges

    var facetFiltersByCategory = filterByCategory.map(category => {
      return "entry.categoriesCollection.items.sys.id:" + category;
    })

    if(facetFiltersByCategory && facetFiltersByCategory.length > 0){
      defaultFacetFilters = [ facetFiltersByCategory ];
    }

    var facetFiltersByRating = filterByRating.map(ratingId => {
      // Lookup for the rating entry object, to be able extract the lowValue and highValue,
      // as only the ID is being maintained in the filterByRating array.
      var ratingEntry = ratings.filter(entry => entry.id == ratingId)[0];

      return `entry.globalRating >= ${ratingEntry.lowValue} AND entry.globalRating <= ${ratingEntry.highValue} `;
    })

    if(facetFiltersByRating && facetFiltersByRating.length > 0){
      defaultNumbericFilters = [ facetFiltersByRating ];
    }

    index
      .search(searchTerm,
        {
        "getRankingInfo": false,
        "analytics": true,
        "enableABTest": false,
        "hitsPerPage": pageSize,
        "attributesToRetrieve": "*",
        "attributesToSnippet": "*:20",
        "snippetEllipsisText": "…",
        "responseFields": "*",
        "maxValuesPerFacet": 100,
        "page": currentPage,
        "facets": [
          "*",                    
          "entry.categoriesCollection.items.sys.id",
          "entry.globalRating"
        ],
        "facetFilters": defaultFacetFilters,
        "numericFilters" : defaultNumbericFilters
      })
      .then(({ hits, facets, nbPages, page, nbHits }) => {        
        setMyCompanies(hits);  
        setCategories(mapToCategoriesFilter(facets));
        setCurrentPage(page);
        setNumberOfPages(nbPages);
        setNumberOfRecords(nbHits);
      })
      .catch(err => {
        console.log(err);
      });
  }

    return (
      <Layout location={props.location}>
         <Helmet title="Resultados de Busqueda - Confiable.club" /> 
         
          <main>              
		<div id="results">
		   <div class="container">
			   <div class="row">
				   <div class="col-lg-3 col-md-4 col-10">
					   <h4><strong>{numberOfRecords}</strong> resultado(s)</h4>
				   </div> 

				    <div class="col-lg-9 col-md-8 col-2">
					   <a href="#0" class="side_panel btn_search_mobile"></a> 
              <div class="row no-gutters custom-search-input-2 inner">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input name="searchTermEntry" 
                        class="form-control" type="text" placeholder="What are you looking for..." value={searchTerm}  onChange={onSearchTermChange} />
                    <i class="icon_search"></i>
                  </div>
                </div>                                
              </div>
				   </div>
          </div> 			   
		   </div>		  
      </div>
      
	   {/* 
		<div class="filters_listing version_2  sticky_horizontal">
			<div class="container">
				<ul class="clearfix">
					<li>
						<div class="switch-field">
							<input type="radio" id="all" name="listing_filter" value="all" checked/>
							<label for="all">All</label>
							<input type="radio" id="popular" name="listing_filter" value="popular"/>
							<label for="popular">Popular</label>
							<input type="radio" id="latest" name="listing_filter" value="latest"/>
							<label for="latest">Latest</label>
						</div>
					</li>
					<li>
						<div class="layout_view">
							<a href="#0" class="active"><i class="icon-th"></i></a>
							<a href="listing-2.html"><i class="icon-th-list"></i></a>
							<a href="list-map.html"><i class="icon-map"></i></a>
						</div>
					</li>
					<li>
						<a class="btn_map" data-toggle="collapse" href="#collapseMap" aria-expanded="false" aria-controls="collapseMap" data-text-swap="Hide map" data-text-original="View on map">View on map</a>
					</li>
				</ul>
			</div>
	
		</div>	*/}
		{/* /filters */}
		
		<div class="collapse" id="collapseMap">
			<div id="map" class="map"></div>
		</div>
		{/* /Map */}
		
		<div class="container margin_60_35" >
			<div class="row">
        <SidebarFilter companies={myCompanies} categories={categories} ratings={ratings}
            onFilterCategoryChange={onFilterCategoryChange} onFilterRatingChange={onFilterRatingChange} />
				
				<div class="col-lg-9">
        {myCompanies && myCompanies.map(record => {
            var company = record.entry;
            return (  
              <CompanyRowView company={company} key={company.sys.id} />                                                      
            )
        })}
					
				<Pagination isVisible={numberOfPages > currentPage} onLoadMoreClick={onLoadMoreClick} />
				</div>
			</div>		
		</div>

	</main>
               
       
      </Layout>
    )
}

export default SearchResults

export const pageQuery = graphql`
  query SearchIndexQuery {
    allContentfulCategory {
      edges {
        node {
          contentful_id
          name
          categorySlug         
        }
      }
    }
  }
`