import React from 'react'
import SidebarFilterByCompany from './sidebar-filter-by-company'
import SidebarFilterByRating from './sidebar-filter-by-rating'

export default ({ categories, companies, ratings, onFilterCategoryChange, onFilterRatingChange }) => (
  
    <aside class="col-lg-3" id="sidebar">
        <div id="filters_col">
            <a data-toggle="collapse" href="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters" id="filters_col_bt">Filtros </a>
            
            <div class="collapse show" id="collapseFilters">
                <SidebarFilterByCompany categories={categories} onFilterCategoryChange={onFilterCategoryChange} />

              {/* 
              Filter by Distance
              <div class="filter_type">
                                <h6>Distance</h6>
                                <div class="distance"> Radius around selected destination <span></span> km</div>
								<input type="range" min="10" max="100" step="10" value="30" data-orientation="horizontal"/>
                    </div> */}
                    <SidebarFilterByRating ratings={ratings} onFilterRatingChange={onFilterRatingChange} />
							
						</div>
						{/*/collapse */}
					</div>
					{/*/filters col*/}
				</aside>
  )
  