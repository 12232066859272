import React from 'react'
    
export default ({ categories, onFilterCategoryChange }) => (
  
  <div class="filter_type">
      <h6>Categorias</h6>
      <ul>
          {categories.map(category => {
              return (
              <li key={category.id}>
                  <label class="container_check">{category.name} 
                  <small>{category.initialCount}</small>
                  <input 
                      name={category.id}
                      type="checkbox"                              
                      onChange={onFilterCategoryChange}
                  />
                  <span class="checkmark"></span>
                  </label>
              </li>
              )})}									
      </ul>
  </div>
  )
  