import React from 'react'
function countCompaniesInScoreLabel() {
    return 0;
  } 

export default ({ ratings, onFilterRatingChange }) => (
  
  <div class="filter_type">
                <h6>Rating</h6>
                <ul>

                {ratings.map(rating => {
                    return (
                    <li key={rating.id}>
                        <label class="container_check">{rating.name} 
                        <small>{rating.initialCount}</small>
                        <input 
                            name={rating.id}
                            type="checkbox"                              
                            onChange={onFilterRatingChange}
                        />
                        <span class="checkmark"></span>
                        </label>
                    </li>
                    )})}		                    
                </ul>
                </div>
  )
  