import React from 'react'
import { Link } from 'gatsby'
import { getCompanyGlobalScore, getCompanyGlobalScoreLabel, getCompanyTotalReviews, getCompanyDrivingAddressLink } from '../utilities/companyHelpers'

export default ({ company }) => (

<div key={company.sys.id}  class="strip list_view" >                              
    <div class="row no-gutters">
    <div class="col-lg-5">
        <figure>
            <Link to={ '/company/' + company.slug } >
                {company.heroImage &&
                <img src={company.heroImage.url + "?w=350&h=300&q=95"} class="img-fluid" alt=""/>
                }
                <div class="read_more"><span>Ver mas</span></div>
            </Link>
        {/* {company.categories && company.categories.map(category => {
            return <small>{category.name}</small>
        })} */}
        </figure>
    </div>
    <div class="col-lg-7">
        <div class="wrapper">                   
        {/* <a href="#0" class="wish_bt"></a> */}
        <h3><Link to={ '/company/' + company.slug } >{company.companyName}</Link></h3>
        {company.primaryAddress && 
        <small>
            {company.primaryAddress.streetAddress1} {company.primaryAddress.streetAddress2} {company.primaryAddress.city}, {company.primaryAddress.state} {company.primaryAddress.country}, {company.primaryAddress.zipCode}
        </small>}
        <p>{company.shortDescription}</p>

        {company.categoriesCollection && company.categoriesCollection.items.map(category => {
            return <span class="loc_open" style={{ marginRight : "5px" }}>{category.name}</span>
        })}
        {/* <a class="address" href={getCompanyDrivingAddressLink(company)}>Obtener dirección</a> */}
        </div>
        <ul>
        {/* <li><span class="loc_open">Now Open</span></li> */}
        <li><div class="score"><span>{getCompanyGlobalScoreLabel(company)}<em>{getCompanyTotalReviews(company)} Testimonios</em></span><strong>{company.globalRating}</strong></div></li>
        </ul>
    </div>
    </div>
</div>
)